import './App.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import {useEffect, useState} from "react";
import Typed from "typed.js";
import { Carousel } from 'react-bootstrap';
// import Contact from "./Contact";
function App() {


    const [scrollPosition, setScrollPosition] = useState({display: 'none'});
    const [navBarscrollPosition, setNavBarScrollPosition] = useState({display: 'none'});

    const [navBarCollapseShow, setNavBarCollapseShow] = useState(false)
    function toggleActiveClass() {
        // Remove active class from all links
        document.querySelectorAll('.nav-item').forEach(function(link) {
            link.classList.remove('active');
        });
        // Add active class to the clicked link
        this.classList.add('active');
    }


    // Add click event listener to all navigation links
    document.querySelectorAll('.nav-item').forEach(function(link) {
        link.addEventListener('click', toggleActiveClass);
    });



    useEffect(() => {


        // Function to handle scroll event
        function handleScroll1() {
            const scrollPos = window.scrollY;



            const aboutPos = document.getElementById('about').offsetTop;
            const skillPos = document.getElementById('skill').offsetTop;
            const servicePos = document.getElementById('service').offsetTop;
            const portfolioPos = document.getElementById('portfolio').offsetTop;
            const testimonialPos = document.getElementById('testimonial').offsetTop;
             // const contactPos = document.getElementById('contact').offsetTop;

            // Determine which section is currently in view
            let activeSectionId = 'home';
            // if (scrollPos >= contactPos) {
            //     activeSectionId = 'contact';
            // }
              if (scrollPos >= testimonialPos) {
                activeSectionId = 'testimonial';
            } else if (scrollPos >= portfolioPos) {
                activeSectionId = 'portfolio';
            } else if (scrollPos >= servicePos) {
                activeSectionId = 'service';
            } else if (scrollPos >= skillPos) {
                activeSectionId = 'skill';
            } else if (scrollPos >= aboutPos) {
                activeSectionId = 'about';
            }

            // Remove active class from all links
            document.querySelectorAll('.nav-item').forEach(function(link) {
                link.classList.remove('active');
            });

            // Add active class to the corresponding link

            document.getElementById(activeSectionId + '-link').classList.add('active');
        }

        // Add scroll event listener to window
        window.addEventListener('scroll', handleScroll1);


        const handleScroll = () => {
            const scrollPositions = window.scrollY;
            const displayStyle = scrollPositions >= 300 ? 'inline' : 'none';
            setScrollPosition({display: displayStyle});
            setNavBarScrollPosition({display: displayStyle === 'inline' ? 'flex' : 'none'});
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const typed = new Typed("#text-input", {
            strings: ["Full-Stack Web Developer", "Web Designer", "Web Developer"],
            typeSpeed: 50,
            loop: true

        });

        return () => {
            typed.destroy();
        };
    }, []);
    return (
        <>
            <nav className="navbar fixed-top shadow-sm navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-lg-5"
                 style={navBarscrollPosition}>
                <a href="/" className="navbar-brand ml-lg-3">
                    <h1 className="m-0 display-5"><span className="text-primary">Abhi</span>Lash</h1>
                </a>
                <button type="button" onClick={() => setNavBarCollapseShow(navBarCollapseShow === false)}
                        className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`collapse navbar-collapse px-lg-3 ${navBarCollapseShow === false ? '' : 'show'}`}
                     id="navbarCollapse">

                    <div className="navbar-nav m-auto py-0">
                        <a href="#home" id="home-link" className="nav-item nav-link active">Home</a>
                        <a href="#about" id="about-link" className="nav-item nav-link">About</a>

                        <a href="#skill" id="skill-link" className="nav-item nav-link">Skill</a>
                        <a href="#service" id="service-link" className="nav-item nav-link">Service</a>
                        <a href="#portfolio" id="portfolio-link" className="nav-item nav-link">Portfolio</a>
                        <a href="#testimonial" id="testimonial-link" className="nav-item nav-link">Review</a>

                        {/*<a href="#contact" id="contact-link" className="nav-item nav-link">Contact</a>*/}
                    </div>
                    <a href="https://www.freelancer.com/u/Abhilashr14001" target="_blank"
                       className="btn btn-outline-primary d-none d-lg-block">Hire Me</a>
                </div>
            </nav>


            <div className="container-fluid d-flex align-items-center mb-5 py-5" id="home"
                 style={{minHeight: "100vh"}}
            >

                <div className="container">

                    <div className="row align-items-center">
                        {/*<div className="col-lg-5 px-5 pl-lg-0 pb-5 pb-lg-0">*/}
                        {/*    <img className="img-fluid w-100 rounded-circle shadow-sm" src="img/profile.jpg" alt=""/>*/}
                        {/*</div>*/}

                    </div>
                </div>
            </div>

            <div className="col-lg-7 text-center text-lg-left bg-text">
                <h3 className="text-white font-weight-normal mb-3">I'm</h3>
                <h1 className="display-3 text-uppercase text-success mb-2"
                    style={{WebkitTextStroke: "2px #ffffff"}}>Abhilash
                    R</h1>
                <h1 className="typed-text-output d-inline font-weight-lighter text-white"></h1>
                <h1 className="typed-text-output d-inline font-weight-lighter text-white"
                    id="text-input"></h1>
                {/*<div className="typed-text d-none">Web Designer, Web Developer, Front End Developer</div>*/}


            </div>


            <div className="container-fluid py-5" id="about">
                <div className="container">
                    <div className="position-relative d-flex align-items-center justify-content-center">
                        <h1 className="display-1 text-uppercase text-white"
                            style={{WebkitTextStroke: "1px #dee2e6"}}>About</h1>
                        <h1 className="position-absolute text-uppercase text-primary">About Me</h1>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-5 pb-4 pb-lg-0">
                            <img className="img-fluid rounded w-100" src="img/about.jpg" alt=""/>
                        </div>
                        <div className="col-lg-7">
                            <h3 className="mb-4">Full-Stack Web Developer</h3>
                            <p>As a seasoned freelance web developer with 5 years of experience, I specialize in
                                creating dynamic and
                                beautiful web applications. I have worked on a variety of projects, mastering
                                technologies like React.js
                                Laravel and Node.js. My portfolio showcases my ability to deliver effective solutions
                                that meet client needs
                                and contribute to their success. I am committed to staying up-to-date with the latest
                                web technologies and
                                industry trends. My dedication to continuous learning and growth ensures that I provide
                                my clients with the
                                most innovative solutions.</p>
                            <div className="row mb-3">
                                <div className="col-sm-6 py-2"><h6>Name: <span
                                    className="text-secondary">Abhilash R</span></h6></div>
                                {/*<div className="col-sm-6 py-2"><h6>Birthday: <span className="text-secondary">10 July 1995</span>*/}
                                {/*</h6></div>*/}
                                {/*<div className="col-sm-6 py-2"><h6>Education: <span*/}
                                {/*    className="text-secondary">Diploma in Computer Science</span></h6></div>*/}
                                <div className="col-sm-6 py-2"><h6>Experience: <span
                                    className="text-secondary">5 Years</span></h6></div>

                                <div className="col-sm-6 py-2"><h6>Email: <span
                                    className="text-secondary">abhilash14001@gmail.com</span>
                                </h6></div>

                                <div className="col-sm-6 py-2"><h6>Freelance Work: <span
                                    className="text-secondary">Available</span></h6>
                                </div>
                            </div>
                            <a href="https://www.freelancer.com/u/Abhilashr14001"
                               className="btn btn-outline-primary mr-4">Hire Me</a>

                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid py-5" id="skill">
                <div className="container">
                    <div className="position-relative d-flex align-items-center justify-content-center">
                        <h1 className="display-1 text-uppercase text-white" style={{WebkitTextStroke: "1px #dee2e6"}}
                        >Skills</h1>
                        <h1 className="position-absolute text-uppercase text-primary">My Skills</h1>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="skill mb-4">
                                <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-bold">HTML</h6>
                                    <h6 className="font-weight-bold">95%</h6>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar bg-primary" role="progressbar" aria-valuenow="95"
                                         aria-valuemin="0"
                                         aria-valuemax="100" style={{width: "95%"}}></div>
                                </div>
                            </div>
                            <div className="skill mb-4">
                                <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-bold">CSS</h6>
                                    <h6 className="font-weight-bold">85%</h6>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar bg-warning" role="progressbar"
                                         style={{width: "85%"}}></div>
                                </div>
                            </div>
                            <div className="skill mb-4">
                                <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-bold">PHP</h6>
                                    <h6 className="font-weight-bold">90%</h6>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar bg-danger" style={{width: "90%"}}></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="skill mb-4">
                                <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-bold">Javascript</h6>
                                    <h6 className="font-weight-bold">90%</h6>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar bg-danger" style={{width: "90%"}}></div>
                                </div>
                            </div>
                            <div className="skill mb-4">
                                <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-bold">React JS</h6>
                                    <h6 className="font-weight-bold">95%</h6>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar bg-dark" role="progressbar"
                                         style={{width: "95%"}}></div>
                                </div>
                            </div>
                            <div className="skill mb-4">
                                <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-bold">Laravel</h6>
                                    <h6 className="font-weight-bold">95%</h6>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar bg-info" role="progressbar"
                                         style={{width: "95%"}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid pt-5" id="service">
                <div className="container">
                    <div className="position-relative d-flex align-items-center justify-content-center">
                        <h1 className="display-1 text-uppercase text-white"
                            style={{WebkitTextStroke: "1px #dee2e6"}}>Service</h1>
                        <h1 className="position-absolute text-uppercase text-primary">My Services</h1>
                    </div>
                    <div className="row pb-3">
                        <div className="col-lg-4 col-md-6 text-center mb-5">
                            <div className="d-flex align-items-center justify-content-center mb-4">
                                <i className="fa fa-2x fa-laptop service-icon bg-primary text-white mr-3"></i>
                                <h4 className="font-weight-bold m-0">Web Design</h4>
                            </div>
                            <p>I create user-friendly and responsive designs that are both functional and visually
                                appealing.</p>
                        </div>
                        <div className="col-lg-4 col-md-6 text-center mb-5">
                            <div className="d-flex align-items-center justify-content-center mb-4">
                                <i className="fa fa-2x fa-laptop-code service-icon bg-primary text-white mr-3"></i>
                                <h4 className="font-weight-bold m-0">Web Development</h4>
                            </div>
                            <p>I develop robust and scalable web applications tailored to your specific business
                                needs.</p>
                        </div>
                        <div className="col-lg-4 col-md-6 text-center mb-5">
                            <div className="d-flex align-items-center justify-content-center mb-4">
                                <i className="fa fa-2x fa-search service-icon bg-primary text-white mr-3"></i>
                                <h4 className="font-weight-bold m-0">SEO</h4>
                            </div>
                            <p>I optimize websites to rank higher in search engine results, increasing visibility and
                                traffic.</p>
                        </div>
                    </div>
                </div>

            </div>


            <div className="container-fluid pt-5 pb-3" id="portfolio">
                <div className="container">
                    <div className="position-relative d-flex align-items-center justify-content-center">
                        <h1 className="display-1 text-uppercase text-white"
                            style={{WebkitTextStroke: "1px #dee2e6"}}>Gallery</h1>
                        <h1 className="position-absolute text-uppercase text-primary">My Portfolio</h1>
                    </div>

                    <div className="row portfolio-container">
                        <div className="col-lg-4 col-md-6 mb-4 portfolio-item first">
                            <div className="position-relative overflow-hidden mb-2">
                                <img className="img-fluid rounded w-100" src="img/portfolio-2.jpg" alt=""/>
                                <div className="d-flex align-items-center justify-content-center">
                                    <a href="img/portfolio-2.jpg" data-lightbox="portfolio">
                                        {/*<i className="fa fa-plus text-white" style={{ fontSize: '60px' }}></i>*/}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4 portfolio-item second">
                            <div className="position-relative overflow-hidden mb-2">
                                <img className="img-fluid rounded w-100" src="img/portfolio-1.jpg" alt=""/>
                                <div className="d-flex align-items-center justify-content-center">
                                    <a href="img/portfolio-1.jpg" data-lightbox="portfolio">
                                        {/*<i className="fa fa-plus text-white" style={{ fontSize: '60px' }}></i>*/}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4 portfolio-item third">
                            <div className="position-relative overflow-hidden mb-2">
                                <img className="img-fluid rounded w-100" src="img/portfolio-3.jpg" alt=""/>
                                <div className="d-flex align-items-center justify-content-center">
                                    <a href="img/portfolio-3.jpg" data-lightbox="portfolio">
                                        {/*<i className="fa fa-plus text-white" style={{ fontSize: '60px' }}></i>*/}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4 portfolio-item first">
                            <div className="position-relative overflow-hidden mb-2">
                                <img className="img-fluid rounded w-100" src="img/portfolio-4.jpg" alt=""/>
                                <div className="d-flex align-items-center justify-content-center">
                                    <a href="img/portfolio-4.jpg" data-lightbox="portfolio">
                                        {/*<i className="fa fa-plus text-white" style={{ fontSize: '60px' }}></i>*/}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4 portfolio-item second">
                            <div className="position-relative overflow-hidden mb-2">
                                <img className="img-fluid rounded w-100" src="img/portfolio-5.jpg" alt=""/>
                                <div className="d-flex align-items-center justify-content-center">
                                    <a href="img/portfolio-5.jpg" data-lightbox="portfolio">
                                        {/*<i className="fa fa-plus text-white" style={{ fontSize: '60px' }}></i>*/}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4 portfolio-item third">
                            <div className="position-relative overflow-hidden mb-2">
                                <img className="img-fluid rounded w-100" src="img/portfolio-6.jpg" alt=""/>
                                <div className="d-flex align-items-center justify-content-center">
                                    <a href="img/portfolio-6.jpg" data-lightbox="portfolio">
                                        {/*<i className="fa fa-plus text-white" style={{ fontSize: '60px' }}></i>*/}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid py-5" id="testimonial">
                <div className="container">
                    <div className="position-relative d-flex align-items-center justify-content-center">
                        <h1 className="display-1 text-uppercase text-white"
                            style={{WebkitTextStroke: "1px #dee2e6"}}>Review</h1>
                        <h1 className="position-absolute text-uppercase text-primary">Clients Say</h1>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="">
                                <Carousel indicators={false}>
                                    <Carousel.Item>
                                        <div className="text-center">
                                            <i className="fa fa-3x fa-quote-left text-primary mb-4"></i>
                                            <h4 className="font-weight-light mb-4">Thank you for the fantastic work and
                                                support, very supportive throughout until the issue was fixed!!
                                                Definitely will hire again if I have PHP and Laravel Projects! Thanks
                                                again</h4>
                                            <img className="img-fluid rounded-circle mx-auto mb-3"
                                                 src="img/testimonial-1.jpg" style={{width: '80px', height: '80px'}}
                                                 alt="Client"/>
                                            <h5 className="font-weight-bold m-0">Client Name</h5>
                                            <span>Raghavendra V M</span>
                                        </div>
                                    </Carousel.Item>
                                    {/*<Carousel.Item>*/}
                                    {/*    <div className="text-center">*/}
                                    {/*        <i className="fa fa-3x fa-quote-left text-primary mb-4"></i>*/}
                                    {/*        <h4 className="font-weight-light mb-4">Dolor eirmod diam stet kasd sed. Aliqu rebum est eos. Rebum elitr dolore et eos labore, stet justo sed est sed. Diam sed sed dolor stet accusam amet eirmod eos, labore diam clita</h4>*/}
                                    {/*        <img className="img-fluid rounded-circle mx-auto mb-3" src="img/testimonial-2.jpg" style={{ width: '80px', height: '80px' }} alt="Client" />*/}
                                    {/*        <h5 className="font-weight-bold m-0">Client Name</h5>*/}
                                    {/*        <span>Profession</span>*/}
                                    {/*    </div>*/}
                                    {/*</Carousel.Item>*/}
                                    {/*<Carousel.Item>*/}
                                    {/*    <div className="text-center">*/}
                                    {/*        <i className="fa fa-3x fa-quote-left text-primary mb-4"></i>*/}
                                    {/*        <h4 className="font-weight-light mb-4">Dolor eirmod diam stet kasd sed. Aliqu rebum est eos. Rebum elitr dolore et eos labore, stet justo sed est sed. Diam sed sed dolor stet accusam amet eirmod eos, labore diam clita</h4>*/}
                                    {/*        <img className="img-fluid rounded-circle mx-auto mb-3" src="img/testimonial-3.jpg" style={{ width: '80px', height: '80px' }} alt="Client" />*/}
                                    {/*        <h5 className="font-weight-bold m-0">Client Name</h5>*/}
                                    {/*        <span>Profession</span>*/}
                                    {/*    </div>*/}
                                    {/*</Carousel.Item>*/}
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/*<div className="container-fluid py-5" id="contact">*/}
            {/*    <div className="container">*/}
            {/*        <div className="position-relative d-flex align-items-center justify-content-center">*/}
            {/*            <h1 className="display-1 text-uppercase text-white"*/}
            {/*                style={{WebkitTextStroke: "1px #dee2e6"}}>Contact</h1>*/}
            {/*            <h1 className="position-absolute text-uppercase text-primary">Contact Me</h1>*/}
            {/*        </div>*/}
            {/*        <Contact />*/}

            {/*    </div>*/}
            {/*</div>*/}


            <div className="container-fluid bg-primary text-white mt-5 py-5 px-sm-3 px-md-5">
                <div className="container text-center py-5">
                    <div className="d-flex justify-content-center mb-4">

                        <a className="btn btn-light btn-social mr-2" href="https://facebook.com/abhilash.ramkrishnappa"
                           target="_blank"><i className="fab fa-facebook-f"></i></a>
                        <a className="btn btn-light btn-social mr-2"
                           href="https://www.linkedin.com/in/abhilash-r-84a2ba1a5/"
                           target="_blank"><i className="fab fa-linkedin-in"></i></a>
                        <a className="btn btn-light btn-social mr-2" href="https://www.instagram.com/abhilashr142"
                           target="_blank"><i
                            className="fab fa-instagram"></i></a>
                        <a className="btn btn-light btn-social" href="https://github.com/abhilash14001/"
                           target="_blank"><i
                            className="fab fa-github"></i></a>
                    </div>

                    <p className="m-0">Copyright &copy; 2024 <a className="text-white font-weight-bold"
                                                                href="#">CodeWithAbhilash.com</a>. All
                        Rights
                        Reserved.
                    </p>
                </div>
            </div>


            <i className="fa fa-2x fa-angle-down text-white scroll-to-bottom"></i>


            <a href="#" style={scrollPosition} className="btn btn-outline-dark px-0 back-to-top"><i
                className="fa fa-angle-double-up"></i></a>
        </>
    );
}

export default App;
